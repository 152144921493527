<template>
  <div class="content" :style="{ backgroundColor: background ? '' : 'unset' }">
    <div class="center fs-18">
      易理货用户服务协议
    </div>
    <div>欢迎您访问海尔易理货！</div>
    <p>
      易理货APP是一款海尔为客户提供运营解决方案的客户端应用，可以帮助客户执行进销存管理、分销业务全流程、门店营销管理、会员运营等全流程管理，通过数字化作业和有效报表分析，提高客户的工作效率。
    </p>
    <p>
      易理货的产品及服务由青岛巨商汇网络科技有限公司（“我们”或“易理货”）以及其他与我们合作的第三方（包括入驻平台的优质品牌及商家资源、注册成为“易理货”的用户）提供。根据您在海尔的客户类型，可分为服务商、零售商、乡镇客户三类，但无论您以哪一种身份使用易理货服务，在使用易理货服务之前，请务必仔细阅读《易理货用户服务协议》（“本服务条款”）。
    </p>
    <p>
      我们将根据本服务条款，通过易理货网站或应用程序（包括易理货主页（域名为yilihuo.com）、易理货App为您提供服务（“本服务”）。您注册、访问本平台和/或使用本服务即代表您认可并愿意接受本服务条款的约束。如您不同意本服务条款或其中任何约定，请您放弃注册，并停止访问和使用本平台及服务。
    </p>
    <p>
      本平台已经发布及后续发布的相关声明、规则及消息（具体见易理货App
      “我的”-“账户设置”- “通用”-
      “易理货帮助”以及“消息”-“平台消息”）为本服务条款的一部分。如您使用本服务，即视为您同意该等声明、规则及消息。就该等声明、规则及消息所涉及的具体事项而言，以该等声明、规则及消息为准。
    </p>
    <p>
      当您通过本平台使用第三方提供的产品或服务时（如激活易理货白条、购买入驻本平台的第三方品牌产品），除遵守本服务条款约定外，还应遵守相关第三方的使用条款和条件。
      我们可酌情在适用法律和法规允许的限度之内不时修订本服务条款。如我们拟对本服务条款进行修订，我们将通过合理方式提前通知您，欢迎您通过该等通知所显示的联系方式对修订内容提出意见。如在本服务条款修订生效之后，您继续访问本平台或使用本服务，则代表您已阅读、理解并无条件同意此类更改。
    </p>
    <div class="fw-500">
      您的易理货账号注册
    </div>
    <p>
      易理货帐号可通过注册或海尔分配获得。如您为海尔的新用户，为使用本服务之目的，您可以直接注册易理货账号。您可以以手机号接收验证码的方式或通过第三方账号进行登录，后者需要您绑定手机号并设置账号密码。您确认并同意，您提供的易理货账号资料真实、准确、合法有效。如您提供的信息不合法、不真实、不准确，您需承担因此引起的相应责任与后果，并且易理货保留终止您账号的权利。
    </p>
    <p>
      您应时刻注意自己的易理货账号安全（例如避免与第三方共用账号）。我们不对他人非法使用您的易理货账号（例如盗用您的账号）或其他您的易理货账号的使用异常情况而导致的损失负责。如您发现您的易理货账号存在被他人非法使用或其他使用异常的情况时，您应立即联系易理货客服，说明情况。我们不对您未能对该等情况进行真实、准确、及时的说明而造成的损失负责。如果发生了上述账号使用异常（或易理货有合理理由怀疑发生了）的情形，易理货保留终止您的易理货账号的权利。
    </p>
    <p>
      您充分了解并同意，由于易理货账号关联您的手机号，未经易理货同意，您不能转让您的易理货账号。
    </p>
    <p>
      您充分了解并同意，您须为您账号下的一切行为负责。为免疑义，易理货不对任何由您账号引起的损害或损失负责。
    </p>
    <p>
      您可以随时选择注销您的易理货账号，我们不会设置不合理的条件来阻止您的注销行为。我们在此善意地提醒您，您注销账户的行为可能会给您后续使用易理货服务以及其他海尔提供的产品和服务带来诸多不便。因您申请注销账号对您造成的不利影响，易理货不承担任何责任。更多关于“注销账号”的内容请浏览《易理货隐私权政策》第九条。
    </p>
    <div class="fw-500">
      知识产权及其他权利
    </div>
    <p>
      除非法律法规明确规定，本平台的所有内容（用户依法享有著作权的内容除外）、技术、软件、程序、数据及其他信息（包括但不限于文字、图片、视频）的所有知识产权（包括但不限于著作权、商标权、专利权等）及相关权利，在您与易理货之间，均归易理货所有，属于易理货的专有财产。未经我们许可，任何人不得以任何形式擅自使用（包括但不限于复制、修改、展示）。
    </p>
    <p>
      在您遵守本服务条款的前提下，易理货授予您非排他性、不可转让、有限、可撤销的个人访问和使用本平台及服务的权限和许可。除了根据本服务条款授予您的权利之外，易理货保留所有本平台及服务包含的或与本平台及服务有关的所有权利。在法律允许的范围内，易理货可在任何时候单独决定不经通知限制访问、暂停、终止提供全部或部分服务。
      您同意仅出于个人目的而非商业目的（成为“海尔智家粉丝”除外）访问本平台、使用本服务，且您同意您不得从事以下行为：
    </p>
    <div>
      1、以任何方式通过任何手段或设备来干扰或试图干扰本平台及服务的操作或使用，包括但不限于黑客或者任何其他方式；
    </div>
    <div>
      2、复制、编辑、修改、改变或摘录本平台及服务
      （或本平台及服务的任何部分）的源代码，或创建其派生作品，或对其进行反向工程、反向汇编、反向编译等；
    </div>
    <div>
      3、删除本平台及服务中关于知识产权权属的信息；
    </div>
    <div>
      4、任何未经易理货明示授权的行为；
    </div>
    <div>
      5、其他本服务条款禁止或违反法律、法规的行为（例如利用本服务进行任何非法交易）。
    </div>
    <p>
      若您从事上述行为或我们有合理理由怀疑您从事上述行为，易理货保留终止您的易理货账号的权利。您应对该等行为引起的任何和全部损失承担责任（包括易理货因此遭受的损失）。
    </p>
    <p>
      为改进本平台及服务之目的，易理货可能会不时更新本平台（包括但不限于
      bug修复、版本升级）。当更新可用时我们会通知您，您可以拒绝安装此类更新。但请您注意，如您拒绝更新，可能会导致您无法使用本平台或本平台的某些服务。更新内容的所有知识产权及相关权利，归易理货所有，属于易理货专有资产。
    </p>
    <div class="fw-500">
      服务的使用
    </div>
    <div>
      本平台通过互联网为您提供购买商品、评论发布等服务。使用本服务，您必须：
    </div>
    <div>
      1、自行配备上网的所需设备，包括个人手机、平板电脑、调制解调器、路由器等；
    </div>
    <div>
      2、自行负担个人上网所支付的与此服务有关的电话费用、网络费用等；
    </div>
    <div>
      3、选择与所安装终端设备相匹配的软件版本，包括但不限于iOS、Android等多个易理货发布的应用版本。
    </div>
    <div>
      4、您可以浏览本平台上由我们、第三方商家或海尔智家粉丝（合称“销售商”）发布的商品或服务信息。您可选购销售商的商品或服务，直接与之达成交易意向并进而完成交易，或请海尔智家粉丝为您下单。如果是第三方商家向您提供商品或服务，则由第三方商家对其商品或服务描述的真实性负责，并承担其商品或服务的质量保证责任，易理货不对任何由其提供的产品或服务作出任何类型的明示或默示担保。如果是海尔智家粉丝向您提供非我们提供或安排的商品或服务，则由海尔智家粉丝自行对该等商品或服务描述的真实性负责，并承担该等商品或服务的质量保证责任，易理货不对该等产品或服务作出任何类型的明示或默示担保。我们会促使第三方商家或海尔智家粉丝尽可能准确、详尽地描述每一件商品或每一项服务。然而我们不能保证本平台上所有第三方商家和海尔智家粉丝对其提供的商品或服务的相关内容是准确、完整、可靠、有效和没有错误的。您应该对您对该等商品或服务所作出的判断负责。使用第三方商家或海尔智家粉丝提供的服务时，您在此许可易理货从该等第三方处接收有关您的特定信息（如适用）。
    </div>
    <div>
      5、直接使用本平台购买商品或服务、下订单或是请海尔智家粉丝帮您下订单时，您应具备购买相关商品或服务的权利能力和行为能力，如果您未满18周岁，您需要在父母或其他法定监护人参与下才能进行购买行为。在您或是海尔智家粉丝代您下订单的同时，即视为您满足上述条件。
    </div>
    <p>
      您理解并同意，本平台上展示的商品/服务和价格等信息仅仅是要约邀请（商品可能存在缺货的情形），您或接受您委托的海尔智家粉丝下单时须填写您希望购买的商品/服务数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您或接受您委托的海尔智家粉丝填写的内容自动生成的数据，仅是您或接受您委托的海尔智家粉丝向销售商发出的合同要约；销售商收到订单信息后，只有在销售商将订单中订购的商品或服务直接向您发出（商品以商品出库为标志；服务以服务人员与您取得联系，确认服务提供的时间等细节为标志），方视为您与销售商之间就实际直接向您发出的商品或服务建立了合同关系。对于视频网站会员卡等数字化商品，您或接受您委托的海尔智家粉丝下单并支付货款后合同即成立。
      尽管销售商做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，本平台无法避免您提交的订单信息中的商品出现缺货情况；如您下单所购买的商品发生缺货，易理货客服会与您取得联系，您有权取消订单；如您同意，销售商亦可取消订单，若您已经付款，则为您办理退款。
    </p>
    <div>
      因如下情况造成配送延迟或无法配送等，我们将不对此承担责任：
    </div>
    <div>
      1、 您提供错误信息和不详细的地址；
    </div>
    <div>
      2、货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果；
    </div>
    <div>
      3、不可抗力，例如：自然灾害、交通戒严等。
    </div>
    <p>
      您在本平台的购买行为应当基于真实的消费需求（无论本人或他人）；您在对本平台的商品或服务进行评价时应当遵循诚信原则。您不得在本平台进行恶意购买、恶意差评等扰乱正常交易秩序的行为。基于维护本平台交易秩序及交易安全的需要，易理货发现上述情形时可主动执行关闭相关交易订单、删除或拒绝发布您的评价信息、终止您的易理货账号等操作。
      易理货可以为促销、推广之目的向您发送与本平台及服务相关的推广资料。如果您不再希望接收某些类别的电子邮件、推送短信或其他推广资料，您可以按照提示来取消订阅。
    </p>
    <p>
      易理货将尽商业上合理努力来持续高质量地为您提供服务。但易理货特此提醒您，除自营产品和服务外，我们的主要角色是作为您获取商品或服务信息、物色交易对象、就商品和/或服务的交易进行协商及开展交易的非物理场所，故我们不对本平台服务进行任何类型的明示或默示担保。您充分了解并同意，您应自行承担访问本平台、使用本服务的风险（如您无法在本平台找到合适的商品或服务、访问本平台期间遭遇令您不适的内容或您无法访问本平台）。
    </p>
    <div>
      如您未能遵守本服务条款或易理货账号适用的任何服务的任何其他条款，易理货可在不事先通知您的情况下终止您的易理货账号。
    </div>
    <div class="fw-500">
      您创建的内容及许可
    </div>
    <div>
      在通过本平台上传、传送、展示或者以其他方式提供任何评价、信息、材料或其他内容（如图片、视频，“用户创建内容”）时，您同意向易理货授予不可撤销的、无限制的、可转授权的、世界范围内的、无偿的、永久的以及非排他性的许可，允许易理货以任何方式或者以现在或以后开发的任何媒介将您的用户创建内容用于任何目的（商业、广告或其他目的），包括但不限于对此类用户创建内容进行复制、修改、编辑、改编、翻译、展示
      （“用户内容使用许可”）。易理货会视其具体使用用户创建内容的情境决定如何署名。如您对是否以及如何署名有专门要求的，请在提供您的用户创建内容时予以书面告知。
    </div>
    <div>
      您应对自己提供的用户创建内容，以及由该用户创建内容所引起的、或与其相联系的任何结果负完全责任（包括易理货因此可能遭受的任何损失）。您特此承诺：
    </div>
    <div>
      1、拥有与用户创建内容相关的所有权利，或已被授权授予易理货“用户内容使用许可”；
    </div>
    <div>
      2、用户创建内容不侵犯任何知识产权或其他任何第三方的权利（例如您应拥有用户创建内容中的每一位可识别个人的明示同意和/或许可，以使用前述每一位可识别个人的姓名或肖像）；
    </div>
    <div>
      3、用户创建内容不包含任何病毒或其他有害代码等；
    </div>
    <div>
      4、用户创建内容不包含“垃圾邮件”或任何未经易理货同意的营销活动；
    </div>
    <div>
      5、用户创建内容不包含任何法律法规、行政规章等（包括但不限于《互联网信息服务管理办法》、《互联网跟帖评论服务管理规定》、《互联网用户账号名称管理规定》）所禁止的其他内容（例如淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的内容），或本服务条款（例如，第四条第8款）禁止的内容。
    </div>
    <p>
      对于任何违反本条承诺的情况，易理货保留删除或拒绝发布任何用户创建内容或终止您的账号的权利。您同意对易理货因您违反上述保证所遭致的任何损失进行赔偿，并保证易理货不招致任何第三方的相关索偿。
    </p>
    <p>
      易理货对用户创建内容概不负责。任何用户创建内容中表达的观点并不代表易理货的观点或价值观。本平台仅作为分发此类用户创建内容的渠道，并不对任何用户创建内容表示认可或对任何用户创建内容或本平台的任何及所有用户的活动承担任何相关义务或责任。在任何情况下，您不应轻信借款、索要密码或其他涉及财产的用户创建内容。
      您的个人信息和隐私保护
    </p>
    <p>
      除了在创建易理货账号时您提供的与自己有关的注册信息之外，我们可能还会要求您提供仅出于向您提供本服务之目的而需要的其他信息。您同意并承诺提供准确、最新和完整的信息。
    </p>
    <p>
      易理货非常重视用户个人信息和隐私的保护。只有在征得您同意后（例如当您注册易理货账号、您委托海尔智家粉丝为您下订单并向其提供了您的信息），易理货才可能收集、使用您使用本服务所需的个人信息（包括但不限于注册信息）。在使用本服务前，请务必仔细阅读本服务的相关隐私权政策，详见《易理货隐私权政策》及其不时发布的补充条款、提示、告知（合称“易理货隐私权政策”）等。易理货隐私权政策是本服务条款不可分割的一部分。就个人信息和隐私保护事宜而言，如本服务条款与易理货隐私权政策存在冲突的，以易理货隐私权政策为准。
    </p>
    <div class="fw-500">
      我们的责任限制
    </div>
    <div>
      如发生下述情形，易理货不对其引发的损害承担法律责任：
    </div>
    <div>
      1、任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足用户要求的情形；
    </div>
    <div>
      2、因通讯线路故障、技术问题、网络、电脑故障、传输延迟、系统不稳定及其他因不可抗力造成的损失的情形；
    </div>
    <div>
      3、其他错误、中断、缺陷和操作所造成损失的情形；
    </div>
    <div>
      4、您违反了本服务条款下的约定，例如，您将本服务用于任何商业或业务用途（成为“海尔智家粉丝”除外），由此可能遭受的业务损失，包括但不限于利润损失、数据丢失、业务中断或商机损失等。
    </div>
    <div>
      5、本服务条款未排除或限制您作为消费者的合法权利：
    </div>
    <div>
      6、如因我们过失而引起死亡或人身伤害责任；
    </div>
    <div>
      7、法律规定禁止排除或限制的其他任何责任。
    </div>
    <div class="fw-500">
      不可抗力
    </div>
    <div>
      如因发生不可抗力情形（本款所称不可抗力，是指不能预见、不能避免并不能克服的客观情况，包括政府主管部门政策的变化等制度性因素）导致我们延迟履行或无法履行本服条款项下的义务，我们不承担责任。易理货可在不可抗力之范围及持续期间内中止履行本服务条款项下之义务直至不可抗力的影响消失。我们将采取合理努力寻找在不可抗力事件下履行本服务条款所述义务的解决方案。
    </div>
    <div class="fw-500">
      其他
    </div>
    <div>
      如本服务条款任何规定被认定为无效、非法或不可执行，其余规定的有效性、合法性和可执行性不受任何影响或削弱。
    </div>
    <div>
      若您和易理货之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交青岛市崂山区有管辖权的人民法院管辖。
    </div>
    <div>
      如您对本平台或本服务条款有任何疑问，请联系：
    </div>
    <!--    <div>-->
    <!--      【客服电话：400-893-9888 】-->
    <!--    </div>-->
    <div>
      【邮寄地址：崂山区松岭路169号 青岛国际创新园 E座14楼】
    </div>
    <br>
    <div>
      更新于：2020年7月13日
    </div>
    <br>
    <div>
      © 青岛巨商汇网络科技有限公司版权所有，保留一切权利。
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      background: this.$route.query.background !== '0'
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  font-size: 14px;
  background-color: #ffffff;
  padding: 10px;
}

.fs-18 {
  font-size: 18px;
}

p {
  text-indent: 25px;
  margin: 0;
}

.center {
  text-align: center !important;
}

.fw-500 {
  font-weight: 600 !important;
}
</style>
